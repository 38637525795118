.add_button {
    position: fixed;
    bottom: 50px;
    left: 50%;
    margin-left: 10% !important;
    width: 80px;
    height: 80px;
    border-radius: 100% !important;
    overflow: hidden;
    transition: all 0.2s !important;
}

.add_button:hover {
    transform: scale(1.3) !important;
}

.add_button_icon {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 24px !important;
    line-height: 24px !important;
}

.add_button_simple {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.add_button_icon_simple {
    margin: 0 5px 0 0 !important;
    height: 100% !important;
}