.editComponentWindow {
    width: 0;
    color: #fff;
    /* background: #222931; */
    background: rgba(255, 255, 255, 0.03);
    border-left: 2px solid #1B2533;
    transition: width 0.2s ease-in-out;
    z-index: 999;
}

.editComponentWindowActive {
    width: 50vw;
}

.editComponentWindowFieldContainerHeadline {
    margin: 0;
}

.editComponentWindowFieldContainer {
    margin: 0 0 10px;
    padding: 10px 0;
    width: 100%;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
}

.editComponentWindowFieldList {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.editComponentWindowFieldListItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 10px 0 0;
}

.editComponentWindowFieldListItemLabel {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    cursor: pointer;
    color: white !important;
    background-color: #FFF !important;
    border: 2px solid white !important;
}

.editComponentWindowFieldListItemLabelHex {
    margin-bottom: 10px !important;
    width: 100px;
}

.editComponentWindowFieldListItemLabelPercentage {
    margin-bottom: 10px !important;
    width: 100px;
}

.editComponentWindowFieldListItemLabelChecked {
    color: white !important;
    background-color: #222931 !important;
    border: 2px solid #3ECF1D !important;
}

.editComponentWindowFieldListItemLabelIcon {
    background: #E8E8E8;
}

.editComponentWindowFieldListItemLabelText {
    padding: 10px;
}

.editComponentWindowFieldListItemLabelSelect {
    width: 100%;
}

.editComponentWindowFieldListItemLabelInputNumber {
    margin-left: 5px;
    margin-right: 5px;
    width: 70px;
}

.editComponentWindowFieldListItemLabelInputPercentage {
    position: relative;
    width: 100%;
}

/* .editComponentWindowFieldListItemLabelInputPercentage:after {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    content: '%';
    color: white;
    font-size: 14px;
    font-weight: bold;
} */

.editComponentWindowFieldListItemLabelInputHex {
    width: 100%;
}

.editComponentWindowFieldListItemLabelInputText {
    width: 100%;
}

.editComponentWindowFieldListItemLabelInputSelect {
    width: 100%;
}

.editComponentWindowField {
    margin: 0 0 20px;
}

.editComponentWindowFieldLabel {
    margin: 0 0 5px !important;
}

.editComponentWindowClose {
    position: absolute;
    top: 11px;
    right: 5px;
}

.editComponentWindowCloseIcon {
    margin: 0 !important;
    padding: 0 !important;
}

.editComponentWindowInner {
    position: relative;
    padding: 0 20px 60px !important;
    height: calc(100vh - 75px);
    z-index: 80;
    box-sizing: border-box;
}

.editComponentWindowScreenSettings {
    margin: 10px -20px 0;
    padding: 20px;
}

.editComponentWindowComponentName {
    margin: 0px -20px 0 -20px;
    padding: 20px 10px;
}

.editComponentWindowActions {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
}

.editComponentWindowActionsButton {
    font-size: 12px !important;
}