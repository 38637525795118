.menuModalLabel {
    display: flex;
    padding: 10px;
    flex-direction: column;
    width: 50px;
}

.menuModalLabelList {
    display: flex;
    flex-direction: row;
}

.menuModalLabelInput {
    margin-top: 10px;
}