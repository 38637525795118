.controls {
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
}

.highlight {
  position: relative;
}

[data-highlight-disabled] .highlight:after {
  background: rgba(255, 0, 0, 0.5) !important;
}

[data-highlight-disabled] .highlightWithoutRelativity:after {
  background: rgba(255, 0, 0, 0.5) !important;
}

.highlight:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(33, 186, 69, 0.5);
  
}

.highlightWithoutRelativity:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(33, 186, 69, 0.5);
}

:not(.preview_mode).controls:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(255, 0, 0, 0.5);
}

:not(.preview_mode).controls:hover .controller {
  display: flex;
}

.controller {
  display: none;
  position: absolute;
  top: 0px;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  background: rgba(34, 41, 49, 0.9);
  z-index: 99;
}

.controller_buttons {
  position: relative;
  display: flex;
}

.controller_button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 15px !important;
}

.controller_button_warning {
  color: white !important;
  background: red !important;
}

.controller_button_warning:hover {
  background: #8F0A0A !important;
}

.controller_button_icon {
  margin: 0 5px 0 0 !important;
}